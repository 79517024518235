
// .notes-container{
//     height: 250px;
//     width: 250px;
//     outline: unset;
//     text-align: left;    
//     overflow-y: scroll;
// }

#notepad{
    padding: 10px;
    height: 250px;
    width: 250px;
    outline: unset;
    text-align: left;    
    overflow-y: scroll;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.notes-container::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE and Edge */
  .notes-container {
    -ms-overflow-style: none;
  }