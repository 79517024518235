.resume-container {
  overflow: scroll;
}

#download-btn {
  bottom: 0;
  right: 0;
  position: absolute;
  background: unset;
  border: unset;
}

.resume-container a {
  padding: 0;
}

.react-pdf__Page__canvas {
  border-radius: 5px;
}

//this will fix the misalignment when selecting text on pdf

.react-pdf__Page__textContent span {
	opacity: 0.1;
}

.react-pdf__Page__textContent span::selection {
	background-color: blue;
}

.react-pdf__Document {
	line-height: initial;
}


// /* Hide scrollbar for Chrome, Safari and Opera */
// .resume-container::-webkit-scrollbar {
//   display: none;
// }

// /* Hide scrollbar for IE and Edge */
// .resume-container {
//   -ms-overflow-style: none;
// }

// ********** Responsive Styles **********

/* viewport 4 : Mobile-landscape (and larger) */
@media (min-width: 480px) {
}

/* viewport 7 : Tablet-portrait (and larger) */
@media (min-width: 768px) {
}

/* viewport 9 : Table-landscape (and larger) */
@media (min-width: 992px) {
  .resume-container {
    height: 80vh;
  }
}

/* viewport 12 : Laptops (and larger)  */
@media (min-width: 1200px) {
}
