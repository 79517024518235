$linear-background: linear-gradient(
    rgb(186, 123, 175),
    rgb(196, 162, 190),
    rgb(206, 189, 203)
  )
  fixed;

body {
  color: black !important;
  height: 100vh;
  overflow: hidden;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.App {
  text-align: center;
  position: relative;

}
