.about-container{
    padding: 10px;
    overflow-y: scroll;
    width: 330px;
    height: 280px;
}

.about-container img{
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: 50% 45%;
    border-radius: 100px;
    margin: 10px;

}


// /* Hide scrollbar for Chrome, Safari and Opera */
// .about-container::-webkit-scrollbar {
//     display: none;

//   }
  
//   /* Hide scrollbar for IE and Edge */
//   .about-container {
//     -ms-overflow-style: none;
//   }