.window-container {
  background: white;
  position: relative;
  display: inline-block;
  margin: 0 auto;
  top: 100px;
  border-radius: 5px;
  box-shadow: rgba(91, 91, 91, 0.317) 1px 1px 20px;
  z-index: 10;
  // resize: both;
  // overflow: auto;
}

.window-container > div {
  border-radius: 5px;
 
}

.window-container .about-container{
  resize: both;
  overflow: auto;
}


.window-container  textarea#notepad{
  resize: both !important;
  border-radius: 5px;
  vertical-align: top;
 
}

#Resume {
  top: 60px;
}

// ********** Responsive Styles **********
/* viewport 4 : Mobile-landscape (and larger) */
@media (min-width: 480px) {
  #Resume {
    top: 45px;
  }
}

/* viewport 7 : Tablet-portrait (and larger) */
@media (min-width: 768px) {
  #Resume {
    top: 20px;
  }
}

/* viewport 9 : Table-landscape (and larger) */
@media (min-width: 992px) {
}

/* viewport 12 : Laptops (and larger)  */
@media (min-width: 1200px) {
}
