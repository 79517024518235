//Gradient colors
$default-color: linear-gradient(
  rgb(186, 123, 175),
  rgb(196, 162, 190),
  rgb(206, 189, 203)
);
$gradient1: linear-gradient(
  rgb(172, 141, 148),
  rgb(177, 163, 168),
  rgb(221, 221, 223)
);
$gradient2: linear-gradient(
  rgb(93, 179, 181),
  rgb(153, 177, 153),
  rgb(162, 162, 164)
);
$gradient3: linear-gradient(
  rgb(44, 169, 227),
  rgb(140, 131, 227),
  rgb(187, 185, 210)
);
$gradient4: linear-gradient(
  rgb(210, 188, 208),
  rgb(174, 172, 187),
  rgb(170, 165, 222)
);

//solid colors
$pink: rgb(223, 187, 218);
$blue: rgb(136, 163, 186);
$purple: rgb(156, 128, 171);
$green: rgb(173, 203, 165);

//exporting variables
:export {
  //exporting gradients
  defaultcolor: $default-color;
  gradient1: $gradient1;
  gradient2: $gradient2;
  gradient3: $gradient3;
  gradient4: $gradient4;

  //exporting solid colors
  pink: $pink;
  blue: $blue;
  purple: $purple;
  green: $green;
}


//styles the whole container for settings
.settings-container {
  width: 250px;
  height: 295px;
}

//styles the div that previews color before changing it
.desktop-preview {
  width: 150px;
  height: 100px;
  // border: black 1px solid;
  margin: 10px auto;
  background-size: cover !important;
}

//styles div containing all dropdown-list for background change
.dropdown-list {
  height: 105px;
  width: 230px;
  margin: auto;
  overflow: scroll;
  text-align: left;
  background: rgb(245, 245, 245);
  border: 1px solid rgb(208, 208, 208);
}

//styles all children divs inside of div with classname of dropdown-list
.dropdown-list > div {
  padding: 5px 10px;
}

//styles all i tag inside of div with classname of dropdown-list
.dropdown-list i {
  font-size: 20px !important;
}

//styles all i tags inside of div with classname of dropdown-list on hover
.dropdown-list i:hover {
  cursor: pointer;
}

//styles the background options
.background-options {
  grid-template-columns: auto auto auto auto;
  justify-items: center;
  display: none;
}

//styles all div elements that are children of classname of background-options
.background-options > div {
  width: 40px;
  height: 30px;
  // border: rgb(151, 151, 151) 1px solid;
  margin: 5px;
  background-size: cover !important;

}

//styles button that applies background color
.apply-btn {
  margin: 15px 0px;
  width: 230px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid rgb(220, 220, 220);
}

//styles button when it is clicked
.apply-btn:active {
  background: rgb(232, 232, 232);
}
