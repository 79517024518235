$window-color: rgba(255, 255, 255, 0.386);

.nav-container{
    background: $window-color;
    text-align: start;
    padding: 0px 10px;
    height: 30px;
    position: relative;
    z-index: 1000;
}

a{
    padding: 5px 10px 5px 0;
    color: black !important;
}

a:hover{
    font-style: unset !important;
    color: black !important;
    text-decoration: none !important;
}

.program-name{
    margin-top: 4px; 
    margin-left: 10px;
    font-weight: 450;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
    .program-name{
        margin-top: 5px; 
    }
}
