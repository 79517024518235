.icon-title {
  background: rgba(255, 255, 255, 0.386);
  display: inline-block;
  padding: 0px 7px;
  font-size: 13px;
}

.icon-img {
  display: block;
  margin: auto;
  margin-bottom: 5px;
  width: 20px;
  height: 20px;
  filter: invert(1);
}
